









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from '@/store/decorators'
import { CustomerItem } from '@/models/Customer'
import { CustomerModule } from '@/store/modules/customerModule'
import { ModelPayload } from '@/models/Payloads'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
@Component({
  name: 'AdminCustomerDetails',
  components: {}

})
export default class AdminCustomerDetails extends Vue {
  @Prop() private uid!: string;

  @Action(CustomerModule, 'fetchAdminCustomerUser') private fetchCustomer!: (payload: ModelPayload) => Promise<CustomerItem>

  private customerItem :CustomerItem | null = null

  private created () {
    this.loadCustomer()
  }
  private async loadCustomer () {
    try {
      this.customerItem = await this.fetchCustomer({
        modelUid: this.uid
      })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: e.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
    }
  }
  @Watch('uid')
  private onUidChanged () {
    // console.log(this.uid)
    this.loadCustomer()
  }
}
